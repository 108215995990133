﻿@mixin breakpoint($class) {

    @if $class == phone {
        @media (max-width: 350px) {
            @content;
        }
    }
  
    @else if $class == extra_small {
        @media (max-width: 480px) {
            @content;
        }
    }
  
    @else if $class == fablet {
        @media (max-width: 767px){
            @content;
        }
    }
  
    @else if $class == sml {
        @media (max-width: 768px) {
            @content;
        }
    }
  
    @else if $class == med {
        @media (max-width: 1024px) {
            @content;
        }
    }
  
    @else if $class == med_tablet {
        @media (max-width: 1200px) {
            @content;
        }
    }
    
    /*==========  Mobile First Method  ==========*/
    @else if $class == bs_min_iPhone {
        @media only screen and (min-width : 320px) {
            @content;
        }
    }
    @else if $class == bs_min_phone {
        @media only screen and (min-width : 480px) {
            @content;
        }
    }
    @else if $class == bs_min_tablet {
        @media only screen and (min-width : 768px) {
            @content;
        }
    }
    @else if $class == bs_min_med {
        @media only screen and (min-width : 992px) {
            @content;
        }
    }
    @else if $class == bs_min_large {
        @media only screen and (min-width : 1200px) {
            @content;
        }
    }
    @else if $class == bs_min_larger {
        @media only screen and (min-width : 1300px) {
            @content;
        }
    }
    
  
    /*==========  Non-Mobile First Method  ==========*/
    @else if $class == bs_max_iPhone {
        @media only screen and (max-width : 320px) {
            @content;
        }
    }
    @else if $class == bs_max_phone {
        @media only screen and (max-width : 480px) {
            @content;
        }
    }
    @else if $class == bs_max_tablet {
        @media only screen and (max-width : 768px) {
            @content;
        }
    }
    @else if $class == bs_max_tablet_800 {
        @media only screen and (max-width : 800px) {
            @content;
        }
    }
    @else if $class == bs_max_med {
        @media only screen and (max-width : 992px) {
            @content;
        }
    }
    @else if $class == bs_max_large {
        @media only screen and (max-width : 1200px) {
            @content;
        }
    }
  
  
    @else {
        @warn "Breakpoint mixin supports: phone, extra_small, small, med, large";
    }
  }
  
  
  
  
  
  