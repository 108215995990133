.personalDetails{
  padding-top: 90px;
  padding-bottom: 70px;
  .course_container h2{
    padding: 24px 0 0 0;
  }

}

.course_flex{
  display: flex;
  flex-direction: row;
  
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .flex_item{
    border: 1px solid $gary_grey;
    width: 30%;
    overflow: auto;
    padding: 16px;
    border-radius: 7px;
    margin-right: 24px;
    margin-top: 16px; 
    @include breakpoint(fablet){
      width: 100%;
      margin: 8px 0;
    }
    ul{
      padding-left: 0;
      margin-bottom: 0;
      li{
        padding: 2px 0; 
        label{
          color: $copy_grey;
          margin-bottom: 0;
        }
      }
    }
  }
}

.course_flex-single{
  border: 1px solid $gary_grey;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 16px;
  border-radius: 7px;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 24px;
  @media screen and (max-width: 991px){
    flex-direction: column;
  }
 
  .course_flex-item{
    margin: 0 16px auto;
    width: 180px;
  }
}


#divProgressBar{
  margin: 22px 0;
  ul.nav{
    margin: 0;
    padding: 0;
    list-style: none;
    @media screen and (max-width: 767px) {
      display: flex;
      width: 95%;
    }
    li{
      @media screen and (max-width: 767px) {
        flex-grow: 1;
      }
      a{
        width: 97%; 
        height: 50px; 
        position: relative; 
        background: $subtel_grey;
        border-radius: 0;
        @media screen and (max-width: 767px) {
          width: auto;
        }
        @include breakpoint(phone){
          height: 50px;
        }
        p{
          margin-left: 15px;
          margin-top: 6px;
          @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-top: 0;
            @include fluid-type(320px, 768px, 10px, 13px);
            top: 7px;
            position: relative;
          }
          @include breakpoint(extra_small){
            top: 7px;
            position: relative;
            left: 6px;
          }
          @include breakpoint(phone){
            // top: 0;
            position: relative;
          }
        }
        &:after{
          content: ""; 
          position: absolute; 
          left: 0; 
          bottom: 0; 
          width: 0; 
          height: 0; 
          border-left: 25px solid $white; 
          border-top: 25px solid transparent; 
          border-bottom: 25px solid transparent;
          @media screen and (max-width: 350px) {
            content: ""; 
            position: inherit; 
            left: inherit; 
            bottom: inherit; 
            width: inherit; 
            height: inherit; 
            border-left: inherit; 
            border-top: inherit; 
            border-bottom: inherit;
          }
        }
        &:before{
          content: ""; 
          position: absolute; 
          right: -25px; 
          bottom: 0;
          width: 0; 
          height: 0; 
          border-left: 25px solid $subtel_grey; 
          border-top: 25px solid transparent; 
          border-bottom: 25px solid transparent;
          z-index: 1;
          @media screen and (max-width: 350px){
            content: ""; 
            position: inherit; 
            right: inherit; 
            bottom: inherit;
            width: inherit; 
            height: inherit; 
            border-left: inherit; 
            border-top: inherit; 
            border-bottom: inherit;
            z-index: inherit;
          }
        }
      }
    }

    li:first-child{
      a{
        &:after{
          content: ""; 
          position: absolute; 
          left: 0; 
          bottom: 0; 
          width: 0; 
          height: 0; 
          border-left: 0px solid $white; 
          border-top: 0px solid transparent; 
          border-bottom: 0px solid transparent;
        }
      }
    }
    li:last-child{
      a{
        &:before{
          content: ""; 
          position: absolute; 
          right: 0px; 
          bottom: 0;
          width: 0; 
          height: 0; 
          border-left: 0px solid $subtel_grey; 
          border-top: 0px solid transparent; 
          border-bottom: 0px solid transparent;
          z-index: 1;
        }
      }
    }
    
    li.active{
      a{
        background-color: $tafe_red;
        &:before{
          content: ""; 
          position: absolute; 
          right: -25px; 
          bottom: 0;
          width: 0; 
          height: 0; 
          border-left: 25px solid $tafe_red; 
          border-top: 25px solid transparent; 
          border-bottom: 25px solid transparent;
          z-index: 1;
          @media screen and (max-width: 350px){
            content: ""; 
            position: inherit; 
            right: inherit; 
            bottom: inherit;
            width: inherit; 
            height: inherit; 
            border-left: inherit; 
            border-top: inherit; 
            border-bottom: inherit;
            z-index: inherit;
          }
        }
      }
    }

    li.active:last-child{
      a{
        &:before{
          content: ""; 
          position: absolute; 
          right: 0px; 
          bottom: 0;
          width: 0; 
          height: 0; 
          border-left: 0px solid $subtel_grey; 
          border-top: 0px solid transparent; 
          border-bottom: 0px solid transparent;
          z-index: 1;
        }
      }
    }
    



    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      li.item1 p, li.item0 p{
        top: 0px;
        position: relative;
      }  
   }  


    @media screen and (max-width: 991px) {

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        li.item1 p, li.item0 p{
          top: 0px;
          position: relative;
        }  
     }
    }
  }
}

.nav-pills > li + li{
  @media screen and (max-width: 767px){
    margin-left: 0;
  }
}

#divProgressBar .step_completed{
  @media screen and (max-width: 767px) {
    display: none;    
  }
}

#divProgressBar span.steps_cheveon{
  display: none;
  @media screen and (max-width: 767px){
    display: none;
    @include fluid-type(320px, 768px, 10px, 13px);
  }
}

#frmPersonalDetails .personalDetails_label {
  @include breakpoint(sml){
    margin-bottom: 0;
  }
}


#frmPersonalDetails{
  margin-bottom: 60px;
  p.personalDetails_instruction{
    color: $title_grey;
    font-size: smaller;
    margin-bottom: 22px;
  }
  label{
    line-height: 15px;
    padding-top: 12px;
    padding-right: 2px;
  }
}

label[for="PersonalDetailsInfo_FirstName"],
label[for="PersonalDetailsInfo_LastName"],
label[for="PersonalDetailsInfo_Email"],
label[for="PersonalDetailsInfo_ConfirmationEmail"],
label[for="PersonalDetailsInfo_MobilePhone"],
label[for="PersonalDetailsInfo_ResidentialBuildingNumber"],
label[for="PersonalDetailsInfo_ResidentialStreet"],
label[for="PersonalDetailsInfo_ResidentialSuburb"],
label[for="PersonalDetailsInfo_ResidentialState"],
label[for="PersonalDetailsInfo_ResidentialPostcode"],
label[for="PersonalDetailsInfo_PostalBuildingNumber"],
label[for="PersonalDetailsInfo_PostalStreet"],
label[for="PersonalDetailsInfo_PostalSuburb"],
label[for="PersonalDetailsInfo_PostalState"],
label[for="PersonalDetailsInfo_PostalPostcode"],
label[for="PersonalDetailsInfo_DateOfBirth"],
label[for="PersonalDetailsInfo_Gender"],
label[for="PersonalDetailsInfo_HasUniqueStudentIdentifier"],
label[for="PersonalDetailsInfo_CITBCardNumber"],
label[for="PersonalDetailsInfo_HasCITBCard"],
label[for="PersonalDetailsInfo_WasBornInAustralia"],
label[for="PersonalDetailsInfo_BirthCountryInput"],
label[for="PersonalDetailsInfo_BirthCountry"],
label[for="PersonalDetailsInfo_YearOfArrival"],
label[for="PersonalDetailsInfo_ResidencyStatus"],
label[for="PersonalDetailsInfo_AboriginalTorresStraitIslander"],
label[for="PersonalDetailsInfo_HomeLanguage"],
label[for="PersonalDetailsInfo_EnglishProficiency"],
label[for="PersonalDetailsInfo_HelpWithEnglish"],
label[for="PersonalDetailsInfo_EmergencyContactFirstName"],
label[for="PersonalDetailsInfo_EmergencyContactLastName"],
label[for="PersonalDetailsInfo_HowFind"],
label[for="PersonalDetailsInfo_HasADisabilityOrMedicalCondition"],
label[for="PersonalDetailsInfo_ReasonForStudy"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
} 

.requiredFieldToggle {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}

#disabilities input.btn, #new-disability input.btn {
  margin-top: 8px;
}


.trippleddldatetime{
  @include breakpoint(sml){
    .col-sm-3{
      width: 22%;
    }
  }
  @include breakpoint(extra_small){
    .col-sm-3{
      width: 100%;
    }
  }
}


@media (max-width: 992px){
  #divProgressBar li.hide_on_mob{
     display: none;
  }
}

.header_spacer{
  padding: 35px;
}



ul.af_list li.af_item {
  padding: 10px !important;
}
