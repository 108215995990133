.inline_Link{
  color: $tafe_red;
  cursor: pointer;
  &:hover{
    color: $tafe_red;
  }
}

.pd_flex{
  display: flex;
  flex-direction: row;
  align-items: center;

  .add_margin-right{
    margin: 0 20px;
  }

  @include breakpoint(bs_max_med){
    flex-direction: column;
    align-items: flex-start;

    .add_margin-right{
      margin: 20px 0;
    }
  }
  
  .form-group{
    margin-bottom: 0;
  }

}

.displayNone{
  display: none;
}

.attch_btn{
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  min-width: 180px;
  width: auto;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  border-radius: 0; 
  transition: none;
  color: $tafe_red;
  border: solid 1px $tafe_red;
  padding: 0;
  background-color: #fff;


  &:hover {
      color: $white ;
      position: relative;
      box-shadow: none;
      background: $tafe_red ;
  }

  @include breakpoint(fablet) {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 32px;
  }

  @media (max-width: 450px) {
    margin: 0;
  }

  @media (max-width: 350px) {
      margin-right: 0;
      margin-left: 0;
      margin-top: 32px;
  }
}

.divUploadDocuments{
  position: relative;
  display: none;
}

.showUploads{
  display: block;
}


::-webkit-file-upload-button {
  background: $white;
  color: $tafe_red;
  padding: 10px 40px;
  border: 1px solid $tafe_red;
  margin-bottom: 4px; 
}

input[type="file"] {
  border: none;
  box-shadow: none;
  padding: 0;
  width: auto;
  height: 40px;
}

.file-upload-wrapper{
  display: flex;
  flex-direction: row;
  // margin-left: 15px;

  input[type="submit"]{
    border-radius: 0;
    top: -2px;
    position: relative;

    &::before{
      content: '\f0aa';
      font-family: 'Font Awesome 5 Free';
      font-size: 18px;
      font-weight: 400;
    }

    &:hover{
      color: $tafe_red !important;
      background-color: $white !important;
    }

    @include breakpoint(fablet){
      margin-top: 12px;
      margin-left: 0;
      width: 152px;
    }
  }

  @include breakpoint(fablet){
    flex-direction: column;
  }
}

.single_attchment_item{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 16px 0;
  border: 1px solid $sc-grey-background;

  @include breakpoint(bs_max_med){
    flex-direction: column;
  }
}

.single_attachment_item-upload{
  margin-right: 15px;
  padding: 18px;
}

.personalDetails_label{
  display: flex;
  flex-direction: column;
}

.has-been-uploaded{
  background-color: $sc-grey-background;
  padding: 16px;
  width: 100%;
  min-height: 132px;
  
  ol.has-been-uploaded-list{

    form{
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }

    li{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.btn-delete-wrapper{
  margin-left: 16px;
  display: flex;
  position: relative;
  margin-bottom: 0;
  font-size: 11px;
  color: #acacac;
  padding: 2px 8px;
  align-items: center;
  border: #ccc solid 1px;

  &:hover{
    color: #333;
    border: 1px solid #333;
  }

  i{
    padding: 0 4px;
    vertical-align: middle;
    font-size: 14px;
  }
}