.flex-container {
	display: flex;
	justify-content: space-evenly;
	list-style: none;
	margin: 0;
	padding: 0;
	-webkit-flex-flow: row wrap;
  position: relative;
  flex-flow: row wrap;
}
.flex-item {
	color: #fff;
	height: 242px;
	margin: 10px 0;
	padding: 0;
	width: 242px;
	position: relative;
}
.flex-item-inner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 242px;
	margin: 0;
	padding: 0;
	width: 242px;
}
.flex-item-inner a {
	color: #fff;
	cursor: pointer;
}

/*-------- cards --------*/
.card-front,
.card-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 240px;
	height: 240px;
	padding: 20px;
	margin: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
  justify-content: flex-end;
  background-color: $tafe_red;
}

.card-front {
    background-image: url("https://www.tafesa.edu.au/images/default-source/hostedassets/tile_background_2.jpg");
    background-size: 242px;
}

.card-front i.tile-icon {
	display: block;
	margin: 10px auto 20px auto;
	text-align: center;
}
.card-front h4 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  font-weight: 700;
  color: $tafe_red;
}
.card-front p.detail {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}

.card-back {
	background-color: $tafe_red;
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}
.card-back p.title {
	color: $copy_grey;
	margin-top: 0;
	text-transform: uppercase;
}
.card-back p.desc {
  color: $white;
  overflow: auto;
  height: 134px;
  text-overflow: ellipsis;
}
.card-back p.link {
  color: $white;
  text-transform: uppercase;
}

/*-------- cards / flip effect --------*/
.flex-item:hover .card-front {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}
.flex-item:hover .card-back {
	-webkit-transform: rotateY(0);
	transform: rotateY(0);
}


ul.additional_resources {
    p {
        display: inline-block;
        top: -5px;
        position: relative;
    }
    a{
				color: $copy_grey;
				&:hover{
					color: $tafe_red;
				}
    }
		li{
			padding: 8px 0;
		}
    li:before {
        background-position: bottom center;
        content: "";
        height: 23px;
        width: 23px;
        display: inline-block;
        background-repeat: no-repeat;
        margin-right: 8px;
        background-image: url("https://www.tafesa.edu.au/docs/default-source/sgvassets/lnr-select.svg");
    }
}