.payment_instructions{
  color: $title_grey;
  font-size: smaller;
  margin-bottom: 22px;
  &:after{
    content: "*";
    color: $tafe_red;
    font-weight: 400;
  }
}

.payment_notes {
    color: $title_grey;
    font-size: smaller;
    margin-bottom: 22px;
}

#frmPaymentDetails{
  img{
    height: 45px;
    top: -2px;
    position: relative;
  }
  label.payment-note{
    font-weight: normal;
  }
}

.total-charge_row{
    min-height: 60px;
}

label[for="PaymentInfo_CreditCardNumber"],
label[for="ddlExpiryMonth"],
label[for="PayerInfo_NameOnCard"],
label[for="PaymentInfo_CVC"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}