//Whole page 
html,
body {
    background: $white;
}

//Button styling



.ecom_btn-theme {
    background: $white ;
    border: 1px solid $tafe_red;
    color: $tafe_red ;
}

.ecom_btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    min-width: 80px;
    width: auto;
    height: 40px;
    font-size: 14px;
    line-height: 23px;
    border-radius: 0; // margin-right: 40px;
    margin-left: 40px; // box-shadow: 1px 1px 3px $dark_red;
    transition: none;
    color: $tafe_red;
    padding: 7px 40px;

    &:hover {
        // top: 1px;
        // left: 1px;
        color: $white ;
        position: relative;
        box-shadow: none;
        background: $tafe_red ;
    }

    @include breakpoint(fablet) {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 32px;
    }

    @media (max-width: 450px) {
        margin-right: 10px;
        margin-left: 0;
    }

    @media (max-width: 350px) {
        margin-right: 0;
        margin-left: 0;
        margin-top: 32px;
    }

}

.btn-checkout {
    // box-shadow: 1px 1px 3px $green;
    border: 1px solid $green ;
    color: $white ;

    &:hover {
        box-shadow: none;
        background: $green ;
        border: 1px solid $green ;
        color: $white ;
    }
}

.eCommerce .header>.navbar>.container {
    padding: 20px 0;
}

// Select Boxes 
select.form-control {
    /* styling */
    background-color: white;
    display: inline-block;
    font: inherit;
    line-height: 45px;
    padding: 0em 3.5em 0em 1em;
    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

select.form-control {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 6px), calc(100% - 15px) calc(1em + 6px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 28px;
    background-repeat: no-repeat;
}

select:focus {
    background-image: linear-gradient(45deg, $tafe_red 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $tafe_red 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 28px;
    background-repeat: no-repeat;
    outline: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

//The red asterisk
span.asterisk {
    color: $tafe_red;
}

//Labels and imputs
input.form-control,
select.form-control {
    height: 45px;
    box-shadow: 1px 1px 3px $subtel_grey;
    border-radius: 1px;
}

input.form-control.students_input{
    background-color: rgba(0,0,0,0);
}


hr.section-separator {
    // border-top: 1px solid $light_grey;
    display: none;
}

.tooltip.top .tooltip-inner,
.tooltip.left .tooltip-inner,
.tooltip.bottom .tooltip-inner {
    background-color: $tafe_red;
    min-width: 250px;
}

.tooltip.left .tooltip-arrow,
.tooltip.top .tooltip-arrow,
.tooltip.bottom .tooltip-arrow {
    border-left-color: $tafe_red;
    border-top-color: $tafe_red;
    border-bottom: $tafe_red;
}

svg.tooltip_img {
    height: 18px;
    width: 18px;
    position: relative; //top: 3px;

    &:hover {
        .st1 {
            fill: $tafe_red;
            opacity: .5;
        }
    }
}

.elevator-button {
    display: none;
    background: none;
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 55px;
    z-index: 100;
    text-decoration: none;
    color: $title_grey;
    background-color: none;
    text-transform: uppercase;
    font-size: 8px;
    text-align: center;
    line-height: 16px;

    &:hover {
        color: $tafe_red;
    }

    span.upchevron {
        font-size: 45px;
    }
}

span.course_name strong,
.course_name {
    color: $tafe_red !important;
}

.form-control::-ms-expand {
    display: none;
}

.form-control + .glyphicon {
    position: absolute;
    right: 16px;
    top: -2px;
    padding: 8px 27px;
}