﻿.cart-item ul {
    list-style-type: none;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.field-validation-error {
    color: #be1428;
    font-weight: bold;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #e80c4d;
}

select.input-validation-error {
    border: 1px solid #e80c4d;
}

.validation-summary-errors {
    color: #be1428;
    font-weight: bold;
    font-size: 1.1em;
}

.validation-summary-valid {
    display: none;
}

// .titleCase {
//     text-transform: capitalize;
// }

.payment-error {
    color: #be1428;
    font-weight: bold;
}

.payment-error h2 {
    color: #be1428;
    font-weight: bold;
}

.payment-error li {
    font-weight: normal;
    color: #be1428;
    list-style-type: circle;
}

.section-separator {
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.applications-fieldset {
    border: 0.1pt solid #000;
    padding: 5px;
}



.applications-course-info-bullets {
    padding: 5px 5px 0 0;
    margin: 16px 0;
}

.payment_table {
    margin-bottom: 15px;
}

.payment_table th {
    background-color: #F5F5F5;
    color: #777777;
    @include fluid-type(320px, 768px, 11px, 14px);
    padding: 10px 5px 10px 5px;
    text-align: center;
    vertical-align: top;


}

.payment_table td {
    color: #444444;
    /*font-size: 12px;*/
    padding: 8px 5px 5px 5px;
    text-align: center;
}

.btn-delete {
    line-height: 2em !important;
    padding-right: 15px;
}

.cart-item-add {
    margin-right: 0px !important;
    color: #707070 !important;
}

.cart-item-minus {
    margin-right: 0px !important;
    color: #707070 !important;
}

.cart-item-quantity {
    max-width: 50px;
    text-align: center;
    display: inline;
    background-color: #fff!important;
}

.text-info {
    font-size: 13px;

}

.enrolling-myself {
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 13px;
    width: 160px;
    text-align: center;

    @media screen and (max-width: 767px) {
        width: 100%;
        text-align: left;
        padding-left: 0;
    }
}

.checkout-main-grid {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}



.btn-checkout {
    background-color: $green ;
    border-color: $green ;
    color: $white !important;
}


.icon-padded {
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 30px;
}

h4.cart-header-student {
    text-align: center;
    padding: 0;
}

.sub-total {
    font-size: 15px;
    font-weight: 700;

}

.btn-update {
    padding-right: 0px;
}

@media (max-width: 479px) {
    .btn-update {
        padding-right: 12px !important;
        padding-bottom: 5px !important;
    }

    .btn-delete {
        padding-left: 0px;
    }
}

@media (max-width: 796px) {
    .btn-delete {
        padding-left: 15px;
    }
}

.info-empty {
    padding-bottom: 1em;
}

.cart-course {
    font-weight: 700;

}

.cart-course-name {
    color: #ba1322;
}

.cart-course-name:visited {
    color: #ba1322;
}

.cart-cost {
    font-weight: 700;

}

.cart-location {
    padding-top: 1px;
    padding-bottom: 1px;
}

.cart-duration {
    padding-top: 1px;
    padding-bottom: 1px;
}