label[for="ChildCareInvoiceDetailsInfo_ChildCareCentre"],
label[for="ChildCareInvoiceDetailsInfo_InvoiceNumber"],
label[for="ChildCareInvoiceDetailsInfo_FirstName"],
label[for="ChildCareInvoiceDetailsInfo_LastName"],
label[for="ChildCareInvoiceDetailsInfo_Email"],
label[for="ChildCareInvoiceDetailsInfo_ConfirmationEmail"],
label[for="ChildCareInvoiceDetailsInfo_Total"] {
    &:after {  
        content: "*";
        color: $tafe_red; 
        font-weight: 400;
    }  
}

