label[for="ApplicantInfo_FirstName"],
label[for="ApplicantInfo_LastName"],
label[for="ApplicantInfo_Email"],
label[for="ApplicantInfo_ConfirmationEmail"],
label[for="ApplicantInfo_MobilePhone"],
label[for="ApplicantInfo_DateOfBirth"],
label[for="ApplicantInfo_Gender"],
label[for="ApplicantInfo_ResidentialAddressInput"],
label[for="ApplicantInfo_ResidentialBuildingNumber"],
label[for="ApplicantInfo_ResidentialStreet"],
label[for="ApplicantInfo_ResidentialSuburb"],
label[for="ApplicantInfo_ResidentialState"],
label[for="ApplicantInfo_ResidentialPostcode"],
label[for="ApplicantInfo_HasUniqueStudentIdentifier"],
label[for="ApplicantInfo_UniqueStudentIdentifier"],
label[for="ApplicantInfo_HasCITBConcession"],
label[for="ApplicantInfo_HasConcession"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}

#applicants .form-group{
  @include breakpoint(fablet){
    margin-bottom: 0px;
  }
}

#applicantDetails{
  margin-bottom: 60px;
  p.applicant_instructions{
    color: $title_grey;
    font-size: smaller;
    margin-bottom: 22px;
  }
  label{
    line-height: 15px;
    padding-top: 12px;
    padding-right: 2px;
  }
}

ul.applications-course-info-bullets{
  li{
    label{
      padding-top: 0!important  ;
      margin-bottom: 0;
    }
  }
}

p.applicant_instructions{
  color: $title_grey;
  font-size: smaller;
  margin-bottom: 22px;
}