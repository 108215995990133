﻿.shopping-cart-placeholder {
  margin-top: 0px;
  margin-left: 30%;
  margin-right: 0px;
  padding-left: 0px;
}

.shopping-cart-placeholder-inapp {
  margin-top: 30px;
  margin-left: 30%;
}

.shopping-cart {
  padding-top: 0px;
  border: none;
  outline: none;
  height: 33px;

  @include breakpoint(bs_max_med){
    height: 24px;
  }
}

// .shopping-cart:hover {
//   padding-top: 0px;
//   border: none;
//   outline: none;
//   height: 33px;
  
//   @include breakpoint(bs_max_med){
//     height: 24px;
//   }
// }

button.shopping-cart {
  color: white;
  background-color: $tafe_red;
  border: none;
  outline: none;
  // left: 8px;

  @include breakpoint(bs_max_tablet){
    background-color: $tafe_red;

  }
}

button.shopping-cart:hover {
  color: $tafe_red ;
  background-color: $white ;
  border: none ;
  outline: none ;
}

.shopping-cart-fa {
  color: white ;
  padding-left: 10px !important;
}

.shopping-cart-fa:hover {
  color: white !important;
}

.shopping-cart-dropdown {
  width: 300px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white !important;
  overflow-y: auto;
  position: absolute;
  left: -230px !important;
  border-radius: 0;
  top: 40px;
}

.cart {
  list-style-type: none;
  background-color: white !important;
  width: auto;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10pt;
  color: #6d6d6d;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.cart li {
  list-style-type: none;
  background-color: white;
  width: auto;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10pt;
  color: #6d6d6d;
  line-height: 1.5;
}

.cart a {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10pt !important;
  color: #ba1322;
}

// .cart a:visited {
//   color: #ba1322;
// }

.cart-empty {
  text-align: center;
  padding-top: 10px;
}

.dropdown-menu .quantity {
  color: #6d6d6d;
  background-color: white;
  padding-right: 10px;
  padding-left: 40px;
  text-align: right;
  float: right;
  overflow: hidden;
}

.cart-dropdown-header {
  list-style-type: none !important;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13pt !important;
  color: #6d6d6d !important;
  padding-left: 0px !important;
  padding-bottom: 10px;
  font-weight: bolder;
}

.cart-divider {
  background-color: #6d6d6d !important;
  color: #6d6d6d !important;
  margin-top: 2em;
  margin-bottom: 0.5em;
  border-style: ridge;
}

.cart-divider-first {
  background-color: #6d6d6d !important;
  color: #6d6d6d !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border-style: ridge;
}

.cartlink {
  white-space: nowrap;
  color: white;
}

.cartlink a {
  color: white ;
  font-size: 14px;

  &:hover{
    color: $tafe_red;
  }
}

.cartlink a:hover {
  text-decoration: none;
}

/**Cart Details**/
.btn span.glyphicon {
  opacity: 0;
}

.btn.active span.glyphicon {
  opacity: 1;
}

.spinner input {
  text-align: right;
}

.loading-animation {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, .8) url('../Images/ajax-loader.gif') 50% 50% no-repeat;
}

.loading-animation-text {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
  text-align: center;
  vertical-align: middle;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  bottom: 30px;
  font-size: 17px;
  width: 250px;
  height: 50px;
  position: center;
  margin-top: -25px;
  margin-left: -125px;
  top: 50%;
  left: 50%;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  -ms-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
  overflow: hidden;
}

/* Anytime the body has the loading class, our
modal element will be visible */
body.loading .modal {
  display: block;
}


.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  min-width: 0;
  box-shadow: none;
  border: none;

  &:hover {
    background: #f2f2f2 !important;
  }
}

/** modal */
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.model-title {
  background-color: orangered !important;
  font-size: 16px;
}

.model-body {
  font-size: 13px;
}

#cartDetails {
  .cart_binicon-wrapper {
    text-align: right;

    span {
      text-align: right;
    }

    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
}

.cart_row-title {
  @media screen and (max-width: 767px) {
    display: none;
  }
}


.cart_container{
    padding: 90px 15px 60px 15px;
    
    @media (max-width: 992px) {
        padding: 136px 15px 60px 15px;
    }
}

.cart_attendees-mobile {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    font-weight: 700;
    padding-top: 16px;
  }
}

.checkout-main-grid-mobile {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.inline-quantity-control {
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
}


.amount_to_be_charged {
  font-weight: 700;
  color: $copy_grey;
  font-size: 22px;
}

.cart-discount-label {
  width: 100%;
  font-weight: 200;

  input.cart-discount-input {
    width: 36%;
    display: inline-block;
    height: 40px;
    border: #ccc solid 1px;
    padding-left: 4px;
    background-color: #E5E5E5;
    transition: ease;

    &:focus {
      background-color: #fff;
    }
  }

  a.cart-discount-btn {
    width: 24%;
    display: inline-block;
    margin-left: -5px;
    padding: 0;
    line-height: 36px;
    top: -1px;
    position: relative;
    background-color: #e5e5e5 !important;
    border-color: #ccc !important;
    color: #ccc;
    margin-top: 0 !important;
  }

  a.cart-discount-btn.discount_focus {
    background-color: #01bc01 !important;
    border-color: #01bc01 !important;
    color: $white;
  }
}

.fees_title {
  text-align: left;
}

.selfenrol-row,
.cart-discount {
  margin: 8px 0;
}

.strikeout {
  text-decoration: line-through;
}

.cart_binicon {
  p {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    top: 4px;
    font-size: 11px;
    color: #acacac;
  }

  i {
    display: inline-block;
    position: relative;
    color: #acacac;
  }

}

span.tooltip-students {
  display: none;
  background-color: $tafe_red;
  position: absolute;
  width: 150px;
  font-size: 11px;
  line-height: 1;
  color: $white;
  padding: 8px;
  transition: ease;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-right: 12px solid $tafe_red;
    position: absolute;
    top: 0;
    left: -12px;
  }
}

svg.tip_payment,
svg.tip_student {
  margin-right: 20px;
}

span.tooltip-payment {
  display: none;
  background-color: $tafe_red;
  position: absolute;
  width: 200px;
  font-size: 11px;
  line-height: 1;
  color: $white;
  padding: 8px;
  transition: ease;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-right: 12px solid $tafe_red;
    position: absolute;
    top: 0;
    left: -12px;
  }
}


.has-def-payment {
  display: block;
}

.cart_note{
  text-align: right;
  font-size: 11px !important;
  margin-top: 16px;
  padding: 0;

  @include breakpoint(extra_small){
    padding-right: 15px;
    padding-left: 15px;
  }

  a{
    color: $tafe_red;
    font-size: 11px !important;
  }
}

.cart_table{
  
  .sc_table-wrapper{
    
    .sc_table-LHS{
      flex-direction: column;

      .sc_table-delivery__row{
        margin-bottom: 22px;
      }
    }

    h4.cart-header-student{
      margin-top: 0;
    }
  }

  .fees_row .sc_table-delivery__row{
    padding-right: 0;
    padding-left: 0;
  }
}



button.shopping-cart{

  .cart_icon_holder{
    background-image: url("/img/assets/headerMenu/cart.svg");
    height: 32px;
    position: relative;
    background-repeat: no-repeat;
    width: 32px;
    display: inline-block;

    @include breakpoint(bs_max_med){
      height: 20px;
      width: 20px;
      background-size: 20px;
    }
  }
  
  @include breakpoint(bs_max_med){
    padding: 2px;
  }
    
  &:hover{
    background-color: $white;
    color: $tafe_red;

    .cart_icon_holder{
      background-position-y: 100%;
    }
    
    @include breakpoint(bs_max_med){
      padding: 2px;
    }

    &:focus{
      color: $tafe_red;
    }
  }

  &:focus{
    color: $white;
  }

  .cart_quantity_holder{
    display: inline;
    position: relative;
    top: -9px;

    @include breakpoint(bs_max_med){
      top: -3px;
    }
  }
}

.shopping-cart-dropdown{

  #cartitems{
    li > a{
      color: $tafe_red;
      width: auto;
      padding: 0;
      border-right: none;
    }

    li.btn > a{
      color: $white;

      &:hover{
        color: $tafe_red;
      }
    }
  }
}