﻿// ====================================================
// Variables
// ====================================================

//Colour Scheme
//transparant
$red: 0;
$blue: 0;
$green: 0;
$alpha: 0;

$black: #000;
$white: #fff;

//Brand Colours
$tafe_red: #be1428;
$light_grey: #bebebe;
$copy_grey: #333333;
$title_grey: #6d6d6d;
$dark_red: #8E0617;
$green: #01bc01;
$subtel_grey: #eee;
$gary_grey: #ccc;
$h2_title_grey: #555;
$sc-grey-background: #E5E5E5;

// Fonts
$title_font: 'Open Sans', sans-serif;
$body_font: 'Open Sans', sans-serif;
$working_font: 'Open Sans', sans-serif;



