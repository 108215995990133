
.education_conatiner{
  margin-bottom: 60px;
}


label[for="EducationInfo_IsStillAtSecondaryEducation"],
label[for="EducationInfo_HighestSecondaryEducationCompleted"],
label[for="EducationInfo_YearHighestSecondaryEducationCompleted"],
label[for="EducationInfo_HasTertiaryEducation"],
label[for="EmploymentInfo_EmploymentStatus"],
label[for="EmploymentInfo_EmploymentSuburb"],
label[for="EmploymentInfo_EmploymentState"],
label[for="EmploymentInfo_EmploymentPostcode"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}

#divTertiary .table > tbody > tr > td{
  padding: 15px 8px 4px 8px;
}

td{
  vertical-align: middle;
}

#frmEducation .education_label {
  @include breakpoint(sml){
    margin-bottom: 0;
  }
}

