.panel {
  margin-bottom: 0px;
  text-decoration: none;
  a{
    text-decoration: none;
    color: $tafe_red;  
    &:hover{
    color: $copy_grey;
    }
    .panel-heading{
      background-color: $subtel_grey;
      h4{
        padding: 16px 0 16px 0;
        display: inline-block;
      }
      i.fa-minus{
        display: inline-block;
        padding: 0 8px;
        font-size: 22px;
        top: 3px;
        position: relative;
      }
      i.fa-plus{
        display: none;
      }
    }
  }
  
  a.collapsed{
    color: $tafe_red;
    .panel-heading{
      background-color: $subtel_grey;
      h4{
        padding: 16px 0 16px 0;
        display: inline-block;
      }
      i.fa-plus{  
        display: inline-block;
        padding: 0 8px;
        font-size: 22px;
        top: 3px;
        position: relative;
      }
      i.fa-minus{
        display: none;
      }
    }
  }
}

#qualifications{
  td{
    div.year {
      text-align: center;
    }
  }
}

.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
  content: "\2212";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion-option {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px 0;
}

.accordion-option .title {
  font-size: 20px;
  font-weight: bold;
  float: left;
  padding: 0;
  margin: 0;
}

#accordion .form-group:not(.accordion_userInput){
  @include breakpoint(fablet){
    margin-bottom: 0  ;
  }
  label{
    @include breakpoint(fablet){
      margin-bottom: 0  ;
    }
  }
}

.student_card-flexWrapper{
  display: flex;
  flex-wrap: wrap;
}

.card-fieldset{
  margin: 8px 8px ;
  width: 260px;
  .student_card{    
    border: 1px $title_grey solid;
    border-radius: 7px;
    
    .student_card-titleblock{
      height: 20px;
      border-radius: 5px 5px 0 0;
      background-color: $tafe_red;
    }
    .card-container{
      padding: 10px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}

table.applicant-card-table i{
  margin-right: 4px;
  position: relative;
  top: 3px;
}

.review_course-wrapper{
  border: 1px $gary_grey solid;
  border-radius: 7px;
  padding: 16px;
  margin: 16px 0;
  h3{
    padding: 0 0 16px 0;
  }
  .students_enrolling-wrapper{
    margin-bottom: 0;
  }
}

#divDisability > table.table.remove-buffer-bottom > tbody > tr > th:nth-child(2) {
  position: relative;
  left: 108px;
  @media screen and (max-width: 767px) {
    left: 0;
  }
}

label[for="PrivacyDetailsInfo_ContactFirstName"],
label[for="PrivacyDetailsInfo_ContactLastName"],
label[for="PrivacyDetailsInfo_Phone"],
label[for="PrivacyDetailsInfo_Email"],
label[for="PrivacyDetailsInfo_ConfirmationEmail"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}