﻿// ====================================================
// Fonts and Typography
// ====================================================


@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

$min_width: 320px;
$max_width: 1200px;
.eCommerce{
    body{
        font-family: $body_font!important;
    }

    h1 {
        @include fluid-type($min_width, $max_width, 18px, 28px);
        font-weight: 700;
        padding: 26px 0 18px 0;
        color: $title_grey;
    }

    h2 {
        @include fluid-type($min_width, $max_width, 16px, 26px);
        color: $h2_title_grey;
        padding: 24px 0 18px 0;
        font-weight: 700;
    }

    h3 {
        @include fluid-type($min_width, $max_width, 15px, 22px);
        color: $title_grey;
        padding: 22px 0 16px 0; 
        font-weight: 700;
    }

    h4 {
        @include fluid-type($min_width, $max_width, 14px, 22px);
        font-weight: 700;
        padding: 20px 0 14px 0;
        color: $title_grey;
    }

    .cart-cost, .cart-course {
        @include fluid-type($min_width, $max_width, 14px, 17px);
    }
}