label[for="ApprenticeshipEnquiryInfo_FirstName"],
label[for="ApprenticeshipEnquiryInfo_LastName"],
label[for="ApprenticeshipEnquiryInfo_Email"],
label[for="ApprenticeshipEnquiryInfo_ConfirmationEmail"],
label[for="ApprenticeshipEnquiryInfo_Phone"],
label[for="ApprenticeshipEnquiryInfo_BusinessName"],
label[for="ApprenticeshipEnquiryInfo_BusinessIndustry"],
label[for="ApprenticeshipEnquiryInfo_EmployerInterest"],
label[for="ApprenticeshipEnquiryInfo_ApprenticeInterest"],
label[for="ApprenticeshipEnquiryInfo_StaffNumber"] {
    &:after {
        content: "*";
        color: $tafe_red;
        font-weight: 400;
    }
}
