.menuFooter.event {
  background: #444;
}


section.menuFooter,
.footer {
    width: 100%;
    display: block;
}
@media (max-width: 479px) {

  .count .col-xs-6,
  .menuFooter .col-xs-6 {
      width: 100%;
  }
}

.count .col-xs-6:nth-child(2),
.menuFooter .col-xs-6:nth-child(2) {
    clear: right;
}


/*=== 1.8 menuFooter ===*/
ul.menuLink,
.social-links ul,
.footer ul.bottom-menu {
    padding-left: 0 !important;
}

.menuLink li {
    list-style: none;
    padding: 10px;
}

.menuLink li a {
    /*changed nuwan */

    line-height: 20px;
    color: #fff;
    /*font-family: 'Open Sans', sans-serif;*/

    font-weight: 400;
    font-size: 16px;
    /*text-transform: uppercase;*/

}

.menuLink li a:hover {
    opacity: 0.8;
}

.menuFooter a,
.menuFooter a:link {
    font-size: 16px;
    color: #fff !important;
}

.menuFooter p {
    font-size: 14px;
    padding: 10px 0;
}

.menuFooter {
    color: #fff;
    width: 100%;
    float: left;
    height: auto;
    padding: 35px 0 0 0;
    margin-top: -1px;
    background-color: #444;
}

.menuFooter .footer-col-inner .subscribe-email {
    width: 70%;
    background: #f5f5f5;
    border: none;
    border-radius: 0;
    float: left;
    margin-right: 5px;
}

@media (max-width:768px) {
    .menuFooter .footer-col-inner .subscribe-email {
        width: 60%;
    }
}

.menuFooter h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: #fff;
}

.menuFooter img.winner {
    margin: 15px 0 20px 0;
    max-width: 100%;
}

@media (max-width: 768px) {
    .menuFooter img.winner {
        margin-top: 0px;
        max-width: 100%;
    }
}

.menuFooter img.whiteRibbon {
    margin-left: 40%;
    max-width: 30%;
}

@media (max-width: 992px) {
    .menuFooter img.whiteRibbon {
        margin-left: 30%;
        max-width: 30%;
    }
}

@media (max-width: 768px) {
    .menuFooter img.whiteRibbon {
        margin-left: 30%;
        max-width: 30%;
    }
}

@media (max-width: 479px) {
    .menuFooter img.whiteRibbon {
        margin-left: 40%;
        max-width: 30%;
    }
}


#menuFooterLinks{
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
    
    .col-xs-12{
      width: auto;
      float: none;

    }
  }
}


.footer_links{
  text-align: center;

  .menuLink {

    li{
      display: inline-block;
      border-right: 1px solid #dedede;
      padding: 0 6px;
      padding-left: 3px;
    }

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      margin: 12px 0;

      li{
        border-right: none;
      }
    }
  }
}

.footer_links .menuLink li:last-child {
  border-right: none;
}

#menuFooterLinks .phone {
  text-align: center;

  p{
    padding: 0;
    margin: 0;
  }

  .social-links{
    display: flex;
    justify-content: center;

    a:hover{
      color: $tafe_red !important;
    }
  }
}

.footer_bottom{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 0 !important;
  padding-left: 0 !important;

  .footer_bottom-logos{
    display: flex;
    align-items: center;
    bottom: 9px;
    position: relative;
  }

  @media (max-width: 992px) {
    flex-direction: column;

  }
}

.footer ul.bottom-menu li{
  
  @media (max-width: 992px){
    font-size: 10px;
  }
}
