﻿// body {
//     padding-top: 10px;
//     padding-bottom: 10px;
// }

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

.res_address {
    position: absolute;
    right: 16px;
    top: -2px;
    padding: 8px 27px;
} 

.checkbox_label {
    margin-top: 6px;
}

.address_checkbox {
    vertical-align: bottom;
    margin-left: 10px;
}

.bulletpoint_list {
    list-style-type: disc;
}